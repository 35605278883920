<template>
    <div>
        <Header />
        <header-content :header-info="headerInfo">
            <header-content-addition :addition="headerInfo" />
        </header-content>
        <div class="faq-list-wrapper">
            <faq-list
                :is-page="isPage"
            />
        </div>
        <Footer />
    </div>
</template>

<script>

import Header from '@/components/header/Header';
import HeaderContent from '@/components/HeaderContent';
import HeaderContentAddition from '@/components/HeaderContentAddition';
import FaqList from '@/components/common/faq/FaqList.vue'
import { documentsBreadcrumbsStepsPages } from '@/helpers/documents/breadcrumbsStepsList';
import Footer from '@/components/Footer';

export default {
    documentsBreadcrumbsStepsPages,
    components: {
        Header,
        HeaderContentAddition,
        HeaderContent,
        FaqList,
        Footer
    },
    metaInfo: {
        title: 'FAQ'
    },
    data() {
        return {
            isPage: false,
            headerInfo: {
                title: 'Frequently Asked Questions',
                className: 'header-content__documents',
                btn_text: 'Ask support',
                btn_link: 'tickets',
                steps: this.$options.documentsBreadcrumbsStepsPages
            }
        }
    }
}
</script>

<style lang="scss">
.faq-list-wrapper {
    margin: 20px 0;
}

</style>
